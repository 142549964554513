var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mission-status",
      class: {
        "mission-status--show": _vm.show,
        "mission-status--hide": !_vm.hideInstant && !_vm.show,
        "mission-status--hide-instant": _vm.hideInstant && !_vm.show,
        "mission-status--correct": _vm.correct,
        "mission-status--wrong": _vm.wrong,
      },
    },
    [_c("div", { staticClass: "mission-status__cnt" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }