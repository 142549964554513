

















import Vue from "vue"
export default Vue.extend({
  name: "MissionStatus",
  props: {
    show: {
      type: [Boolean, Number, String],
      default: true
    },
    correct: {
      type: Boolean,
      default: undefined
    },
    wrong: {
      type: Boolean,
      default: undefined
    },
    hideInstant: {
      type: Boolean,
      default: false
    }
  }
})
